<template>
<div id="footer">
  <div class="info">
    <div class="sub-info">
      <div class="clinic-info">
        <div class="part">
          <div class="info-logo">
            <img src="../assets/hope.png" alt="hope" width="70px">
            <div class="name">のぞみクリニック学芸大</div>
          </div>
          <div class="text">TEL：03-3760-3717 FAX：03-3760-3718</div>
          <div class="text">お問い合わせ：nozomi-jim@hope-medical.jp</div>
        </div>
        <div class="part">
          <div class="title"></div>
          <!-- <div class="title">病院情報</div>
          <div class="text">診療科目：内科、皮膚科、糖尿病治療、プラセンタ、健康診断</div>
          <div class="text">診療時間：9:30～13:30 / 15:00～19:00</div> -->
          <div class="text">〒152-0004</div>
          <div class="text">東京都目黒区鷹番3-1-5グリーンアース２階</div>
          <div class="text">東急東横線　学芸大学駅東口より徒歩１分</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="clinic-info">
        <div class="part">
          <div class="info-logo">
            <img src="../assets/hope.png" alt="hope" width="70px">
            <div class="name">のぞみクリニック築地</div>
          </div>
          <div class="text">TEL/FAX：03-6260-6008</div>
          <div class="text">お問い合わせ：info@hope-medical.jp</div>
        </div>
        <div class="part">
          <div class="title"></div>
          <!-- <div class="title">病院情報</div>
          <div class="text">診療科目：総合内科、ダイエット</div>
          <div class="text">診療時間：9:00～13:00 / 14:00～18:00</div> -->
          <div class="text">〒104-0045</div>
          <div class="text">東京都中央区築地7-11-5 中銀ベル築地マンシオン2階</div>
          <div class="text">東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分</div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright"><span>Copyright©{{thisYear}} Hope Medical. All rights reserved.</span></div>
</div>
</template>

<script>
export default {
  name: "MFooter",
  data() {
    return {
      thisYear: (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped>
#footer {
  background-color: #edf7fc;
}

.info {
  padding: 1rem;
}
.divider {
  border-top: 2px solid #cccccc;
  margin: 1.5rem 2rem;
}
.info-logo {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.info-logo .name {
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #004686;
}
.part .title {
  margin-top: 2rem;
  color: #333;
  font-size: 16px;
}
.part>div:nth-child(4){
  margin-top: 1rem;
}
.part .text {
  color: #333;
  font-size: 14px;
  margin: 5px 0;
}

.copyright {
  width: 100%;
  height: 38px;
  background-color: #1796c4;
  font-size: 12px;
  color: #fbfbfd;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
