<template>
<div id="vaccine">
  <div>
    <img src="../assets/img_main1.png" alt="" width="100%">
  </div>
  <!--<div class="m-title">新型コロナウイルス・ワクチン接種について</div>-->
  <!--<div class="m-text">当クリニックで新型コロナウイルス・ワクチン接種（武田/モデルナ社製）を行うことになりました。</div>-->
  <!--<div class="m-text">２回目が未接種の方も、是非お申込みください。</div>-->
  <!--<div class="m-text m-center">【記】</div>-->
  <!--<div class="m-text m-left">接種日時：10月5日（火）より毎週火曜日～金曜日　午後1:30～4:30（最終受付4:00）</div>-->
  <!--<div class="m-text m-left">場　 所：東京都中央区築地7-11-5　中銀ベル築地マンシオン2階 のぞみクリニック築地</div>-->
  <!--<div class="m-text m-left">東京メトロ日比谷線 / 築地駅 徒歩4分</div>-->
  <!--<div class="m-text m-left">対象年齢：満12歳以上</div>-->
  <!--<div class="m-text m-center">【接種のご予約】</div>-->
  <!--<div class="m-text m-left">予約開始日：10月1日（金）より</div>-->
  <!--<div class="m-text m-left">予約受付時間：月曜日~金曜日　9:00～17:30</div>-->
  <!--<div class="m-text m-left">予約受付電話番号：03-6260-6008</div>-->
  <!--<div class="m-text">主として首都圏の方々が対象になりますが、数に限りがあります。</div>-->
  <!--<div class="m-text">接種希望だったが、まだ予約が取れていない皆さま、いわゆる「モデルナ難民」（２度目の接種予約ができない）の皆さま、是非ご予約ください。</div>-->
  <!--<div class="m-text">お問い合わせ：医療法人社団 天裕会　のぞみクリニック築地</div>-->
  <!--<div class="m-text">Mail：rapha99yama@hope-medical.jp</div>-->
  <!--<div class="m-text">電話：03‐6260‐6081</div>-->
</div>
</template>

<script>
export default {
  name: "MVaccine"
}
</script>

<style scoped>
#vaccine {
  background-color: #FFFFFF;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 10px;
}
.m-title {
  text-align: center;
  color: #004686;
  font-size: 24px;
  line-height: 26px;
  margin: 1rem;
}
.m-text {
  color: #707070;
  line-height: 23px;
  margin-bottom: 5px;
}
.m-center {
  text-align: center;
  margin: 1rem 0;
}
.m-left {
  padding-left: 2rem;
}
</style>
