<template>
<div id="home">

  <div class="hidden-sm-and-down">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div class="header">
          <div class="logo"><img src="../assets/hope_36x50@2x.png" alt="Hope" width="36px"></div>
          <div class="name">
            <div class="name-hope">医療法人社団天裕会</div>
            <div class="name-clinic">のぞみクリニック 学芸大・築地</div>
          </div>
          </div>
        <div class="all-clinic">
          <div class="clinic" @click="goHref('gakugeidai')">
            <div class="image"><img src="../assets/map_gakugeidai.png" alt="のぞみクリニック学芸大" width="100%" height="100%"></div>
            <div class="clinic-info">
              <div class="info-logo">
                <div class="name-hope">医療法人社団天裕会</div>
                <div class="name-clinic">のぞみクリニック学芸大</div>
              </div>
              <div class="part">
                <!-- <div class="text">診療科目：内科、皮膚科、糖尿病治療、プラセンタ、健康診断</div>
                <div class="text">診療時間：9:30～13:30 / 15:00～19:00 （受付30分前に）</div>
                <div>休診日：月曜・水曜・木曜・金曜・日曜・祝日・年末年始（9月末まで）</div> -->
                <div class="address">
                  <div class="text">〒152-0004</div>
                  <div class="text">東京都目黒区鷹番3-1-5グリーンアース２階</div>
                  <div class="text">東急東横線　学芸大学駅東口より徒歩１分</div>
                </div>
                <div class="contact">
                  <div class="text">TEL：03-3760-3717　FAX：03-3760-3718</div>
                  <div class="text">お問い合わせ：nozomi-jim@hope-medical.jp</div>
                </div>
              </div>
            </div>
          </div>
          <div class="clinic" @click="goHref('tsukiji')">
            <div class="image"><img src="../assets/map_tsukiji.png" alt="のぞみクリニック築地" width="100%" height="100%"></div>
            <div class="clinic-info">
              <div class="name-hope">医療法人社団天裕会</div>
              <div class="name-clinic">のぞみクリニック築地</div>
              <div class="part">
                <!-- <div class="text">診療科目：総合内科、ダイエット</div>
                <div class="text">診療時間：10:00～13:30 / 14:30～19:00</div>
                <div>休診日：日曜・祝日・年末年始</div> -->
                <div class="address">
                  <div class="text">〒104-0045</div>
                  <div class="text">東京都中央区築地7-11-5 中銀ベル築地マンシオン2階</div>
                  <div class="text">東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分</div>
                </div>
                <div class="contact">
                  <div class="text">TEL/FAX：03-6260-6008</div>
                  <div class="text">お問い合わせ：info@hope-medical.jp</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="footer">
      <!-- <div class="info">
        <div class="sub-info">
          <div class="clinic-info">
            <div class="part">
              <div class="info-logo">
                <img src="../assets/hope.png" alt="hope" width="70px">
                <div class="name">のぞみクリニック学芸大</div>
              </div>
              <div class="text">TEL：03-3760-3717 FAX：03-3760-3718</div>
              <div class="text">お問い合わせ：nozomi-jim@hope-medical.jp</div>
            </div>
            <div class="part">
              <div class="title">病院情報</div>
              <div class="text">診療科目：内科、漢方内科、小児科、皮膚科</div>
              <div class="text">診療時間：9:30～13:30 / 15:00～19:00</div>
              <div class="text">〒152-0004</div>
              <div class="text">東京都目黒区鷹番3-1-5グリーンアース２階</div>
              <div class="text">東急東横線　学芸大学駅東口より徒歩１分</div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="clinic-info">
            <div class="part">
              <div class="info-logo">
                <img src="../assets/hope.png" alt="hope" width="70px">
                <div class="name">のぞみクリニック築地</div>
              </div>
              <div class="text">TEL/FAX：03-6260-6008</div>
              <div class="text">お問い合わせ：info@hope-medical.jp</div>
            </div>
            <div class="part">
              <div class="title">病院情報</div>
              <div class="text">診療科目：胃腸科、漢方内科、腫瘍外科</div>
              <div class="text">診療時間：9:00～13:00 / 14:00～18:00</div>
              <div class="text">〒104-0045</div>
              <div class="text">東京都中央区築地7-11-5 中銀ベル築地マンシオン2階</div>
              <div class="text">東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="copyright"><span>Copyright©{{thisYear}} Hope Medical. All rights reserved.</span></div>
    </div>
  </div>

  <div class="hidden-md-and-up">
    <div class="m-logo"><img src="../assets/hope.png" alt="Hope" width="120"></div>
    <div class="m-all-clinic">
      <!-- <m-vaccine/> -->
      <div class="m-clinic" @click="goHref('gakugeidai')">
        <div>のぞみクリニック</div>
        <div>学芸大</div>
      </div>
      <div class="m-clinic" @click="goHref('tsukiji')">
        <div>のぞみクリニック</div>
        <div>築地</div>
      </div>
    </div>
    <m-footer/>
  </div>

</div>
</template>

<script>
import MFooter from "../components/MFooter";
import MVaccine from "../components/MVaccine";
export default {
  name: "Home",
  components: {MVaccine, MFooter},
  data() {
    return {
      thisYear: (new Date()).getFullYear()
    }
  },
  methods: {
    goHref(path) {
      let url = ''
      if(path == 'gakugeidai'){
        url = `https://www.hope-gakugeidai.com/`
      }else{
        url = `https://www.hope-tsukiji.com/`
      }
      window.location.href = url
      // window.location.href = `https://www.hope-medical.jp/${path}`
    }
  }
}
</script>

<style scoped>
/* PC */
.header {
  align-items: center;
  display: flex;
  margin-top: 20px;
}
.name {
  margin-left: 14px;
}
.all-clinic {
  margin: 30px 0;
  display: flex;
  justify-content: flex-start;
}
.clinic {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  cursor: pointer;
  display: inline-block;
  padding: 20px;
  margin-right: 20px;
}
.clinic:hover {
  border: 2px solid #62b6e2;
  box-shadow: rgba(0, 0, 0, 0.24) 0 6px 12px;
}
.image {
  width: 400px;
  height: 400px;
}
.clinic-info {
  padding: 30px;
  line-height: 1.6;
}
.name-hope {
  color: #aaaaaa;
  font-size: 14px;
}
.name-clinic {
  color: #62b6e2;
  font-size: 18px;
}
.part {
  margin-top: 20px;
  font-size: 14px;
}
.address {
  margin-top: 14px;
  font-size: 12px;
}
.contact {
  margin-top: 14px;
  font-size: 12px;
}

/*最下面版权申明*/
.footer .copyright {
  width: 100%;
  height: 50px;
  background-color: #62b6e2;
  font-size: 14px;
  color: #fbfbfd;
  letter-spacing: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* SP */
.m-logo {
  margin: 8px 1rem;
}
.m-all-clinic {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-clinic {
  margin: 1rem 0;
  width: 70%;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.m-clinic>div {
  color: #004686;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
</style>
