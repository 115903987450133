import { render, staticRenderFns } from "./MFooter.vue?vue&type=template&id=69c1376d&scoped=true&"
import script from "./MFooter.vue?vue&type=script&lang=js&"
export * from "./MFooter.vue?vue&type=script&lang=js&"
import style0 from "./MFooter.vue?vue&type=style&index=0&id=69c1376d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c1376d",
  null
  
)

export default component.exports